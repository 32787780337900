import * as React from "react"
import { Layout } from "../../components/layout"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Pedigree } from "../../components/dogs/pedigree"
import NotFoundPage from "../404"
import { Show } from "../../components/dogs/show"

const DogPage = (props) => {
  if (props.data.hundeYaml.type === 5) {
    return <NotFoundPage />
  }
  const data = props.data.hundeYaml
  return (
    <>
      <Layout
        title={`${data.fullName} (${data.name})`}
        image={data.mainImage.childImageSharp.gatsbyImageData}
      >
        <main>
          <div className='container-lg'>
            <h1 className='text-capitalize'>{data.name}</h1>
            <h3 className='pb-3'>{data.fullName}</h3>
            <section className='row py-3 justify-content-md-center'>
              <GatsbyImage
                className='col-12 col-md-6 shadow'
                image={data.src.childImageSharp.gatsbyImageData}
                alt={data.name}
                loading='lazy'
              />
              <article className='col-12 col-md-6 pt-4'>
                {data.health.birthDate ? (
                  <p>
                    <b>geboren am:</b> {data?.birthDate}
                  </p>
                ) : null}
                {data.health.hd ? (
                  <p>
                    <b>HD:</b> {data?.health?.hd}
                  </p>
                ) : null}
                {data.health.ed ? (
                  <p>
                    <b>ED:</b> {data?.health?.ed}
                  </p>
                ) : null}
                {data.health.eyes ? (
                  <p>
                    <b>Augen:</b> {data?.health?.eyes}
                  </p>
                ) : null}
                {data.health.gebiss ? (
                  <p>
                    <b>Gebiss:</b> {data?.health?.gebiss}
                  </p>
                ) : null}
                {data.health.formwert ? (
                  <p>
                    <b>Formwert:</b> {data.health.formwert}
                  </p>
                ) : null}
                {data.health.wesenstest ? (
                  <p>
                    <b>Wesenstest:</b> {data.health.wesenstest}
                  </p>
                ) : null}
                {data.health.dna ? <p>{data.health.dna}</p> : null}
                {data.description ? <p>{data.description}</p> : null}
              </article>
              {data.standingImg ? (
                <GatsbyImage
                  className='col-12 col-md-6 shadow mt-3'
                  image={data.standingImg.childImageSharp.gatsbyImageData}
                  alt={data.name}
                  loading='lazy'
                />
              ) : null}
            </section>
          </div>
          <div
            className='accordion accordion-flush pt-3 container-lg px-0'
            id='accordionFlushExample'
          >
            <div className='accordion-item'>
              <h4 className='accordion-header' id='flush-headingOne'>
                <button
                  className='accordion-button collapsed fw-bold'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#flush-collapseOne'
                  aria-expanded='false'
                  aria-controls='flush-collapseOne'
                >
                  Gallerie
                </button>
              </h4>
              <div
                id='flush-collapseOne'
                className='accordion-collapse collapse'
                aria-labelledby='flush-headingOne'
                data-bs-parent='#accordionFlushExample'
              >
                <div className='accordion-body px-0 py-3 row g-2 justify-content-evenly'>
                  {data.gallerie.map((img) => (
                    <GatsbyImage
                      imgClassName='shadow'
                      className='col-12 col-md-6'
                      image={img.childImageSharp.gatsbyImageData}
                      alt={data.name}
                      objectFit='contain'
                      loading='lazy'
                    />
                  ))}
                </div>
              </div>
            </div>
            {data.wesenstest || data.formwert || data.begleithunde ? (
              <div className='accordion-item'>
                <h4 className='accordion-header' id='flush-headingTwo'>
                  <button
                    className='accordion-button collapsed fw-bold'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#flush-collapseTwo'
                    aria-expanded='false'
                    aria-controls='flush-collapseTwo'
                  >
                    Prüfungen
                  </button>
                </h4>
                <div
                  id='flush-collapseTwo'
                  className='accordion-collapse collapse'
                  aria-labelledby='flush-headingTwo'
                  data-bs-parent='#accordionFlushExample'
                >
                  <div className='accordion-body'>
                    <section>
                      {data.formwert ? (
                        <article>
                          <h3>Formwert</h3>
                          <p>
                            <b>Richter: </b>
                            {data.formwert.richter}
                          </p>
                          <p>
                            <b>Beurteilung: </b>
                            {data.formwert.bewertung}
                          </p>
                        </article>
                      ) : null}
                      {data.wesenstest ? (
                        <article>
                          <h3>Wesenstest</h3>
                          <p>
                            <b>Richter: </b>
                            {data.wesenstest.richter}
                          </p>
                          <p>
                            <b>Beurteilung: </b>
                            {data.wesenstest.bewertung}
                          </p>
                          <p>
                            <b>Schuss: </b>
                            {data.wesenstest.schuss}
                          </p>
                          {data.wesenstest.bemerkungen && (
                            <p>
                              <b>Bemerkungen: </b>
                              {data.wesenstest.bemerkungen}
                            </p>
                          )}
                        </article>
                      ) : null}
                    </section>
                  </div>
                </div>
              </div>
            ) : null}
            {data.shows ? (
              <div className='accordion-item'>
                <h4 className='accordion-header' id='flush-headingTwo'>
                  <button
                    className='accordion-button collapsed fw-bold'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#flush-austellungen'
                    aria-expanded='false'
                    aria-controls='flush-collapseTwo'
                  >
                    Austellungen
                  </button>
                </h4>
                <div
                  id='flush-austellungen'
                  className='accordion-collapse collapse'
                  aria-labelledby='flush-headingTwo'
                  data-bs-parent='#accordionFlushExample'
                >
                  <div className='accordion-body'>
                    <Show shows={data.shows} />
                  </div>
                </div>
              </div>
            ) : null}
            <div className='accordion-item'>
              <h4 className='accordion-header' id='flush-headingThree'>
                <button
                  className='accordion-button collapsed fw-bold'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#flush-collapseThree'
                  aria-expanded='false'
                  aria-controls='flush-collapseThree'
                >
                  Ahnentafel
                </button>
              </h4>
              <div
                id='flush-collapseThree'
                className='accordion-collapse collapse'
                aria-labelledby='flush-headingThree'
                data-bs-parent='#accordionFlushExample'
              >
                <div className='accordion-body'>
                  <Pedigree father={data.father} mother={data.mother} />
                </div>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>
  )
}

export const query = graphql`
  query ($id: String) {
    hundeYaml(id: { eq: $id }) {
      name
      fullName
      birthDate
      type
      description
      health {
        hd
        ed
        eyes
        formwert
        wesenstest
        gebiss
      }
      formwert {
        richter
        bewertung
      }
      wesenstest {
        richter
        bewertung
        schuss
        bemerkungen
      }
      gallerie {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      mainImage {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      standingImg {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      src {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      shows {
        location
        date
        result
        class
      }
      father {
        normalName
        img {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        father {
          father {
            name
          }
          mother {
            name
          }
          name
        }
        mother {
          father {
            name
          }
          mother {
            name
          }
          name
        }
        name
      }
      mother {
        normalName
        img {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        father {
          father {
            name
          }
          mother {
            name
          }
          name
        }
        name
        mother {
          father {
            name
          }
          mother {
            name
          }
          name
        }
      }
    }
  }
`

export default DogPage
