import * as React from "react";
import { Link } from "gatsby";
import { Layout } from "../components/layout";

const NotFoundPage = () => {
  return (
    <main>
      <Layout>
        <main className="container">
          <h1>Diese Seite wurde nicht gefunden</h1>
          <br />
          <Link to="/">Zurück zur Startseite...</Link>
        </main>
      </Layout>
    </main>
  );
};

export default NotFoundPage;
