import React from "react"

export const Show = ({ shows }) => (
  <>
    <div className='row align-items-center'>
      <table className='table table-striped table-bordered text-center'>
        <thead>
          <tr>
            <th>Ort</th>
            <th>Datum</th>
            <th>Klasse</th>
            <th>Beurteilung</th>
          </tr>
        </thead>
        <tbody>
          {shows.map((show) => (
            <tr>
              <td>{show?.location}</td>
              <td>{show?.date}</td>
              <td>{show?.class}</td>
              <td>{show?.result}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </>
)
